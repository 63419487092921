import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/axiosBaseQuery";
import { PartialBy } from "utils/declare";

export enum OperationType {
  Add = 1, // "+"
  Sub = 2, // "-"
  Mul = 3, // "*"
  Div = 4, // "/"
  Pow = 5, // "^"
  Mod = 6, // "%"
  LBr = 7, // "("
  RBr = 8, // ")"
}

export enum FormulaEntityType {
  Number = 1,
  Operation = 2,
  Question = 3,
}
export interface FormulaEntity {
  type: FormulaEntityType;
  value: number | OperationType | null;
}

export enum QuizBtnValue {
  "SQUARE" = 1,
  "ROUND" = 2,
  "SQUARE_ROUND" = 3,
  "SQUARE_FILLED" = 4,
  "ROUND_FILLED" = 5,
  "SQUARE_ROUND_FILLED" = 6,
  "SQUARE_ICON_FILLED" = 7,
  "SQUARE_ICON" = 8,
  "ROUND_ICON_FILLED" = 9,
  "ROUND_ICON" = 10,
}
export interface TextConfig {
  bold: boolean;
  italic: boolean;
  underline: boolean;
}

export interface AdditionaInfo {
  addition_page_display?: number;
  addition_position_display?: number;
}

export interface ContactFormInfo {
  form_design_type?: number;
  form_title?: string;
  form_title_config?: TextConfig;
  form_description?: string;
  form_description_config?: TextConfig;

  messengers?: boolean;
  messengers_config?: { [key: string]: boolean };

  privacy_policy?: boolean;
  privacy_policy_link?: string;
}
export interface ThanksPageInfo {
  thank_title?: string;
  thank_title_config?: TextConfig; //TODO:Not needs

  thank_description?: string;
  thank_description_config?: TextConfig;
  thank_button?: boolean;
  thank_button_text?: string;
  thank_link?: string;
  thank_is_auto_redirect?: boolean;
  thank_auto_redirect_link?: string;
}

export interface QuizInfo
  extends ContactFormInfo,
    ThanksPageInfo,
    AdditionaInfo {
  id: number;
  title: string;
  title_config?: TextConfig;
  description?: string;
  description_config?: TextConfig;
  design_type?: number;
  button_style?: QuizBtnValue;
  button_color?: string;
  button_text?: string;
  button_text_color?: string;
  button_text_config?: TextConfig;
  background_color?: string;
  text_color?: string;
  image?: string | null;
  project: number;
  language?: number;
  font?: number;
  progress_bar_style?: number | null;
  currency?: number;

  //formData?: FormData;

  after_contacts?: boolean;
  send_email?: boolean;
  many_result?: boolean;
  result_design_type?: number;
  result_title?: string;
  result_title_config?: TextConfig;
  result_description?: string;
  result_description_config?: TextConfig;
  formula?: { config: FormulaEntity[] };

  is_published?: boolean;
  date_publication?: string | null;
  date_creation?: string;
  is_main_page_enabled?: boolean;
}

export const quizzesApi = createApi({
  reducerPath: "quizzesApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Quizzes"],
  endpoints: (builder) => ({
    getQuizzes: builder.query<QuizInfo[], void>({
      query: () => ({ url: "quiz/", method: "GET" }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Quizzes", id } as const)),
              { type: "Quizzes", id: "LIST" },
            ]
          : [{ type: "Quizzes", id: "LIST" }],
    }),
    getQuizById: builder.query<QuizInfo, number>({
      query: (id) => ({ url: `quiz/${id}/`, method: "GET" }),
      providesTags: (result, error, id) => [{ type: "Quizzes", id }],
    }),
    createQuiz: builder.mutation<QuizInfo, Omit<QuizInfo, "id">>({
      query: (data) => ({ url: "quiz/", method: "POST", data }),
      invalidatesTags: [{ type: "Quizzes", id: "LIST" }],
    }),
    copyQuiz: builder.mutation<QuizInfo, number>({
      query: (id) => ({ url: "quiz/copy/", method: "POST", data: { id } }),
      invalidatesTags: [{ type: "Quizzes", id: "LIST" }],
    }),
    updateQuiz: builder.mutation<QuizInfo, PartialBy<QuizInfo, "id">>({
      query: (data) => {
        const { id, ...body } = data;
        return { url: `quiz/${data.id}/`, method: "PATCH", data: body };
      },
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const [getQuizById, getQuizzes] = updateCacheQuiz({ id, ...patch });
        const patchResult = dispatch(getQuizById);
        const patchList = dispatch(getQuizzes);
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
          patchList.undo();
          dispatch(quizzesApi.util.invalidateTags([{ type: "Quizzes", id }]));
        }
      },
      //invalidatesTags: (result, error, { id }) => [{ type: "Quizzes", id }],
    }),
    updateQuizImage: builder.mutation<
      QuizInfo,
      { id: number; formData: FormData }
    >({
      query: (data) => {
        return {
          url: `quiz/${data.id}/`,
          method: "PATCH",
          data: data.formData,
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: "Quizzes", id }],
    }),
    deleteQuiz: builder.mutation<void, number>({
      query: (id) => ({ url: `quiz/${id}/`, method: "DELETE" }),
      invalidatesTags: (result, error, id) => [{ type: "Quizzes", id }],
    }),

    publishQuiz: builder.mutation<void, number>({
      query: (id) => ({
        url: `/publication-quiz/`,
        method: "POST",
        data: { quiz: id },
      }),
      invalidatesTags: (result, error, id) => [{ type: "Quizzes", id }],
    }),
    disableQuiz: builder.mutation<void, { id: number; is_published: boolean }>({
      query: ({ id, is_published }) => ({
        url: `/quiz/${id}/publish/`,
        method: "PUT",
        data: { is_published },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Quizzes", id }],
    }),
  }),
});

export const {
  useGetQuizzesQuery,
  useGetQuizByIdQuery,
  useCreateQuizMutation,
  useDeleteQuizMutation,
  useUpdateQuizMutation,
  useUpdateQuizImageMutation,
  useCopyQuizMutation,
  usePublishQuizMutation,
  useDisableQuizMutation
} = quizzesApi;

export const updateCacheQuiz = ({
  id,
  ...patch
}: PartialBy<QuizInfo, "id">) => {
  return [
    quizzesApi.util.updateQueryData(
      "getQuizById",
      id,
      (draft: Partial<QuizInfo>) => {
        Object.assign(draft, patch);
      }
    ),
    quizzesApi.util.updateQueryData(
      "getQuizzes",
      undefined,
      (draft: QuizInfo[]) => {
        Object.assign(draft.find((item) => item.id === id) ?? {}, patch);
      }
    ),
  ];
};

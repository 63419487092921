import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/axiosBaseQuery";
import { PartialBy } from "utils/declare";


export interface IntegrationWebhookInfo {
    id: number
    quiz: number
    link: string
    is_enabled: boolean
    password_hash?: string | null
}

export const useGetActionWebhook = (quizId: number) => {
    const { data: webhookIntegration } = useGetWebhookByQuizIdQuery(quizId)
    const [updateWebhookEnabled, { isLoading }] = useUpdateWebhookEnabledMutation()

    const isEnabled = webhookIntegration?.find(item => item.is_enabled)


    const updateWebHook = () => {
        updateWebhookEnabled({ quiz: quizId, is_enabled: !(!!isEnabled) })
    }

    return {
        isEnabledWebHook: !!isEnabled,
        canEnableWebHook: !!webhookIntegration,
        isLoadingWebHook: isLoading,
        updateWebHook
    }
}

export const integrationWebhooksApi = createApi({
    reducerPath: "integrationWebhooksApi",
    baseQuery: axiosBaseQuery(),
    tagTypes: ["IntegrationWebhooks"],
    endpoints: (builder) => ({
        getWebhookByQuizId: builder.query<IntegrationWebhookInfo[], number>({
            query: (quizId) => ({ url: `webhook-integration/quiz/${quizId}/`, method: "GET" }),
            providesTags: (result, error, id) => {
                return [{ type: "IntegrationWebhooks", id }];
            },
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                try {
                    await queryFulfilled;
                } catch (err: any) {
                    if (err.error && err.error.status === 404) {
                        dispatch(integrationWebhooksApi.util.upsertQueryData('getWebhookByQuizId', arg, null as any));
                    }
                }
            },
        }),
        createWebhook: builder.mutation<
            IntegrationWebhookInfo,
            Omit<IntegrationWebhookInfo, "id">
        >({
            query: (data) => ({ url: "webhook-integration/", method: "POST", data }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                dispatch(integrationWebhooksApi.util.invalidateTags([{ type: "IntegrationWebhooks", id: data.quiz }]));
            },
        }),
        updateWebhookEnabled: builder.mutation<void, Pick<IntegrationWebhookInfo, 'quiz' | 'is_enabled'>>({
            query: ({ quiz, is_enabled }) => ({ url: `webhook-integration/quiz/${quiz}/set_enabled/`, method: "POST", data: { is_enabled } }),
            invalidatesTags: (result, error, { quiz }) => {
                return [{ type: "IntegrationWebhooks", id: quiz }];
            },
        }),
        updateWebhook: builder.mutation<
            IntegrationWebhookInfo,
            PartialBy<IntegrationWebhookInfo, "quiz">
        >({
            query: (data) => {
                const { id, quiz, ...body } = data;
                return { url: `webhook-integration/${id}/`, method: "PATCH", data: body };
            },
            async onQueryStarted({ id, quiz, ...patch }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(updateCacheWebhook({ id, quiz, ...patch }));
                try {
                    const result = await queryFulfilled;
                    dispatch(updateCacheWebhook({ ...result.data }))
                } catch {
                    patchResult.undo();
                    dispatch(
                        integrationWebhooksApi.util.invalidateTags([{ type: "IntegrationWebhooks", id: quiz }])
                    );
                }
            },
        }),
        deleteWebhook: builder.mutation<void, Pick<IntegrationWebhookInfo, 'id' | 'quiz'>>({
            query: ({ id }) => ({ url: `webhook-integration/${id}/`, method: "DELETE" }),
            invalidatesTags: (result, error, { quiz }) => {
                return [{ type: "IntegrationWebhooks", id: quiz }];
            },
        }),
    }),
});

export const {
    useGetWebhookByQuizIdQuery,
    useCreateWebhookMutation,
    useUpdateWebhookMutation,
    useDeleteWebhookMutation,
    useUpdateWebhookEnabledMutation,
} = integrationWebhooksApi;

export const updateCacheWebhook = ({
    id,
    quiz,
    ...patch
}: PartialBy<IntegrationWebhookInfo, "quiz">) => {
    return integrationWebhooksApi.util.updateQueryData(
        "getWebhookByQuizId",
        quiz,
        (draft: Partial<IntegrationWebhookInfo[]>) => {
            for (const webhook of draft) {
                if (webhook && webhook.id === id) {
                    Object.assign(webhook, patch);
                    break;
                }
            }
        }
    );
};
